.distribute-controls {
    animation: slideInDistributeControls .5s ease-in-out forwards;
    right: 0;
    .token-container {
        width: 90px;
        .token {
            margin: 0 auto;
            &__attachment {
                width: 100%;
            }
        }
    }
    .timer {
        margin-right: 20px;
        margin-left: 20px;
    }
}