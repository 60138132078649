.discuss-and-distribute-scene {
    overflow: hidden;
    min-height: 100%;
    margin: 0 auto !important;
    background-color: $lightBackgroundColor;
    max-width: 1280px;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        height: 415px;
        right: 0;
        left: 0;
        width: 100%;
        background-color: $darkBackgroundColor;
    }
    .projects-container {
        position: relative;
        background-color: $darkBackgroundColor;
        padding: 10px 20px 30px 20px;
        display: flex;
        flex-wrap: wrap;
        height: 415px;
        .project-card {
            width: calc(20% - 20px);
            margin: 10px;
        }
        &__bottom {
            background: $black;
            padding: 20px 10px 0 10px;
            display: flex;
            align-items: center;
            width: 100%;
            h3 {
                font-weight: normal;
                font-style: italic;
                color: $grey;
                white-space: nowrap;
            }
        }
        &.distribution {
            .projects-container__bottom {
                display: none;
                position: relative;
                span {
                    &:first-of-type {
                        height: 40px;
                        border-right: 1px solid $grey;
                        border-top: 1px solid $grey;
                        border-top-right-radius: 40px;
                        width: 100px;
                        position: absolute;
                        margin-left: 10px;
                        left: 125px;
                        top: 32px;
                        z-index: 1;
                        &::after {
                            content: '';
                            right: -4px;
                            bottom: 0px;
                            position: absolute;
                            @include lineEndSquare;
                            animation: fadeIn .5s ease-in-out;
                        }
                    }
                    &:last-of-type {
                        height: 40px;
                        border-right: 1px solid $grey;
                        border-top: 1px solid $grey;
                        border-top-right-radius: 40px;
                        width: 105px;
                        position: absolute;
                        margin-left: 10px;
                        top: 32px;
                        z-index: 1;
                        &::after {
                            content: '';
                            right: -4px;
                            bottom: 0px;
                            position: absolute;
                            @include lineEndSquare;
                            animation: fadeIn .5s ease-in-out;
                        }
                        right: 10px;
                        width: calc(100% - 590px);
                        transform: rotateY(180deg);
                        &::before {
                            content: '';
                            left: 0;
                            top: -5px;
                            position: absolute;
                            @include lineChartRulerDot;
                            animation: fadeIn .5s ease-in-out;
                        }
                    }
                }
            }
        }
        &.discussion {
            .projects-container__bottom {
                display: none;
                position: relative;
                span {
                    height: 40px;
                    border-right: 1px solid $grey;
                    border-top: 1px solid $grey;
                    border-top-right-radius: 40px;
                    width: calc(100% - 330px);
                    position: absolute;
                    margin-left: 10px;
                    top: 32px;
                    z-index: 1;
                    left: 125px;
                    &::after {
                        content: '';
                        right: -4px;
                        bottom: 0px;
                        position: absolute;
                        @include lineEndSquare;
                        animation: fadeIn .5s ease-in-out;
                    }
                    &:last-of-type {
                        display: none;
                    }
                }
            }
        }
    }
    .discuss {
        background-color: $lightBackgroundColor;
        padding: 20px 40px 0 40px;
        display: flex;
        justify-content: space-between;
        .discuss-container {
            width: 100%;
        }
        h2 {
            font-size: 2.625rem;
            font-weight: bold;
            font-style: normal;
            color: $white;
        }
        .level-explanation {
            width: 210px;
            min-width: 210px;
            align-self: end;
        }
    }
    .distribute {
        position: relative;
        background-color: $lightBackgroundColor;
        padding: 20px 40px;
        display: flex;
        height: calc(100% - 415px);
        h2 {
            font-size: 2.625rem;
            font-weight: bold;
            font-style: normal;
            color: $white;
        }
        &__left-container {
            width: calc(100% - 210px);
            z-index: 1;
            pointer-events: none;
            .player-card-stack {
                animation: playerCardStackSlideUp .5s ease-in-out forwards;
                margin-top: 0;
            }
        }
        .level-explanation {
            width: 210px;
            min-width: 210px;
            align-self: end;
        }
    }

    .bonus-move-available {
        animation: fadeIn .5s ease-in-out;
        display: flex;
        align-items: center;
        position: absolute;
        // top: 20px;
        right: 300px;
        p {
            color: $grey;
            display: flex;
            white-space: nowrap;
            .spacing {
                margin: 0 10px;
            }
            margin-right: 20px;
        }
    }
}

.controls-container {
    position: absolute;
    display: flex;
    padding: 20px;
    padding-top: 0;
    background-color: $darkBackgroundColor;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    bottom: -62px;
    z-index: 1;
    &__right-top {
        position: absolute;
        background-color: $black;
        height: 40px;
        width: 40px;
        right: -40px;
        top: 10px;
        &::after {
            content: '';
            background-color: $lightBackgroundColor;
            border-top-left-radius: 50%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
        }
    }
    &__left-top {
        position: absolute;
        background-color: $black;
        height: 40px;
        width: 40px;
        left: -40px;
        top: 10px;
        &::after {
            content: '';
            background-color: $lightBackgroundColor;
            border-top-right-radius: 50%;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
        }
    }
}
