.progress-dashboard {
    position: relative;
    &__results {
        display: flex;
        background-color: $darkBackgroundColor;
        height: 100%;
    }
    &__bottom {
        display: flex;
        background: $black;
        align-items: center;
        padding: 0 60px 0 40px;
        margin-top: -55px;
        padding-bottom: 10px;
        h3 {
            color: $grey;
            font-weight: normal;
            font-style: italic;
            z-index: 1;
            white-space: nowrap;
            margin-right: 20px;
            background: $black;
            padding: 10px 0;
        }
        span {
            width: 100%;
            height: 1px;
            background-color: $grey;
            position: relative;
            &::after {
                content: '';
                top: -5px;
                right: -5px;
                position: absolute;
                @include lineChartRulerDot;
                z-index: 1;
            }
        }
    }
}