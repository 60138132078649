@mixin lineChartRulerDot() {
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 4px;
    border: 1px solid $grey;
    background: black;
}

@mixin lineEndSquare() {
    width: 6px;
    height: 6px;
    display: block;
    transform: rotateZ(45deg);
    border: 1px solid $grey;
    background: $black;
}

@mixin titleAppendixEndDot() {
    width: 6px;
    height: 6px;
    display: block;
    border-radius: 4px;
    border: 1px solid $grey;
    background: $white;
}

@mixin titleAppendixEndHalfDot() {
    width: 6px;
    height: 6px;
    display: block;
    border-top-right-radius: 100%;
    transform: rotateZ(45deg);
    border: 1px solid $grey;
    background: $white;
}
