.player-ranking {
    padding: 66px 40px;
    height: 100%;
    width: 225px;
    &__title-container {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        h3 {
            color: $grey;
            font-weight: normal;
            font-style: italic;
            white-space: nowrap;
            margin-right: 15px;
        }
        span {
            width: 100%;
            height: 1px;
            background-color: $grey;
            position: relative;
            &::after {
                content: '';
                top: -5px;
                right: -5px;
                position: absolute;
                @include lineChartRulerDot;
                z-index: 1;
            }
        }
    }
    .loader {
        margin: 40px auto;
    }
    &__rankings {
        display: flex;
        flex-direction: column;
        &__rank {
            padding: 0 20px 5px 20px;
            opacity: 0;
            animation: fadeIn .5s ease-in-out forwards;
            &.winner {
                p {
                    font-weight: bold;
                    font-style: normal;
                }
            }
            display: flex;
            align-items: center;
            .token {
                width: 36px;
                height: 38px;
            }
            p {
                color: $white;
                font-style: italic;
                font-size: 1rem;
                margin-left: 7.5px;
                white-space: nowrap;
            }
        }
    }
}