@keyframes openCard {
    0% {
        height: 100%;
    }
    100% {
        height: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn50 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: .5;
    }
}

@keyframes fadeInDisplayBlock {
    0% {
        display: none;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}

@keyframes applyTokenConsequence {
    0% {
        transform: scale(1) translateY(0);
    }
    100% {
        transform: scale(1.1) translateY(-10px);
    }
}

@keyframes tokenEffect {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-20px);
        opacity: 0;
    }
}

@keyframes fadeOut {
    100% {
        opacity: 0;
    }
}

@keyframes popUp {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes scaleUp {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.25);
    }
}

@keyframes popUpAndTranslateY {
    0% {
        transform: scale(0) translateY(-50%);
    }
    100% {
        transform: scale(1) translateY(-50%);
    }
}

@keyframes slideInDiscussControls {
    0% {
        transform: translateX(200px);
    }
    100% {
        transform: translateX(-40px);
    }
}

@keyframes slideInShowResultControls {
    0% {
        transform: translateX(-150px);
    }
    100% {
        transform: translateX(40px);
    }
}

@keyframes slideInDistributeControlsWallet {
    0% {
        transform: translateX(-200px);
    }
    100% {
        transform: translateX(300px);
    }
}

@keyframes slideInDistributeControls {
    0% {
        transform: translateX(200px);
    }
    100% {
        transform: translateX(-40px);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(40px);
    }
    100% {
        transform: translateY(0)
    }
}

@keyframes slideInLevelControls {
    0% {
        transform: translate(-50%, 100px);
    }
    100% {
        transform: translate(-50%, 0);
    }
}

@keyframes slideOutLevelControls {
    0% {
        transform: translate(-50%, 0);
    }
    100% {
        transform: translate(-50%, 100px);
    }
}

@keyframes playerCardStackSlideUp {
    0% {
        transform: translateY(125px);
        opacity: 0;
    }
    100% {
        transform: translateY(-100px);
        opacity: 1;
    }
}

@keyframes islandFullScreen {
    100% {
        top: 0;
        right: 0;
        width: 100%;
    }
}


@keyframes pulse {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(59, 147, 24, 1);
	}

	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 10px rgba(59, 147, 24, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(59, 147, 24, 0);
	}
}

@keyframes pulseCG {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(202, 17, 59, 1);
	}

	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 10px rgba(202, 17, 59, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(202, 17, 59, 0);
	}
}

@keyframes pulseGF {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(238, 166, 77, 1);
	}

	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 10px rgba(238, 166, 77, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(238, 166, 77, 0);
	}
}

@keyframes pulsePF {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(224, 94, 43, 1);
	}

	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 10px rgba(224, 94, 43, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(224, 94, 43, 0);
	}
}

@keyframes pulseIE {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(78, 157, 174, 1);
	}

	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 10px rgba(78, 157, 174, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(78, 157, 174, 0);
	}
}

@keyframes pulseFL {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(81, 165, 140, 1);
	}

	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 10px rgba(81, 165, 140, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(81, 165, 140, 0);
	}
}

@keyframes pulseII {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(173, 188, 198, 1);
	}

	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 10px rgba(173, 188, 198, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(173, 188, 198, 0);
	}
}

@keyframes pulseFF {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(175, 108, 69, 1);
	}

	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 10px rgba(175, 108, 69, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(175, 108, 69, 0);
	}
}

@keyframes pulseFG {
	0% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(243, 164, 138, 1);
	}

	70% {
		transform: scale(1.05);
		box-shadow: 0 0 0 10px rgba(243, 164, 138, 0);
	}

	100% {
		transform: scale(1);
		box-shadow: 0 0 0 0 rgba(243, 164, 138, 0);
	}
}
