/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on October 9, 2020 */



@font-face {
    font-family: 'etelka_text_probold_italic';
    src: url('etelka-text-pro-bold-italic-webfont.woff2') format('woff2'),
         url('etelka-text-pro-bold-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'etelka_text_probold';
    src: url('etelka-text-pro-bold-webfont.woff2') format('woff2'),
         url('etelka-text-pro-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'etelka_text_proitalic';
    src: url('etelka-text-pro-italic-webfont.woff2') format('woff2'),
         url('etelka-text-pro-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'etelka_text_proregular';
    src: url('etelka-text-pro-webfont.woff2') format('woff2'),
         url('etelka-text-pro-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}