.level-explanation {
    animation: fadeIn .5s ease-in-out;
    h2 {
        color: $white;
        font-size: 3.5rem;
        margin-bottom: 10px;
    }
    p {
        color: $grey;
        span {
            font-weight: bold;
            font-style: italic;
        }
    }
    p:first-of-type {
        margin-bottom: 20px;
    }
}