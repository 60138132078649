@media only screen and (min-height: 975px) {
    .players-slider {
        &__inner {
            .items {
                .player-with-wallet {
                    &.hide {
                        display: flex !important;
                    }
                }
            }
        }
        &__controls {
            display: none !important;
        }
    }
}

.players-slider {
    width: 100%;
    display: flex;
    &__inner {
        width: calc(100% - 240px);
        .items {
            position: relative;
            display: flex;
            left: -30px;
            flex-wrap: wrap;
            .player-with-wallet {
                &.hide {
                    display: none;
                }
                margin: 0 15px;
                margin-bottom: 20px;
                min-width: calc(25% - 30px);
                width: calc(25% - 30px);
            }
        }
    }
    &__controls {
        display: flex;
        position: relative;
        width: 240px;
        align-items: flex-end;
        cursor: pointer;
        .player-card {
            position: absolute;
            bottom: 30px;
            transform: translateX(-50%) rotate(0deg);
            animation: fadeIn .5s ease-in-out;
            &__inner {
                img {
                    box-shadow: 6px 0 5px 0 rgba(0, 0, 0, 0.2);
                    border-radius: 5px;
                    // animation: fadeIn .5s ease-in-out;
                }
            }
        }
        button {
            width: 8px;
            height: 8px;
            background-color: $white;
            border: none;
            border-radius: 100%;
            padding: 0;
            margin: 0 8px;
            opacity: .5;
            cursor: pointer;
            &.active {
                opacity: 1;
            }
        }
        p {
            margin: 0;
            color: $grey;
            font-size: 1rem;
            font-weight: bold;
            font-style: italic;
            text-align: center;
            width: 100%;
            span {
                font-weight: normal;
            }
        }
    }
}