.loading-scene, .loading-overlay {
    height: 100%;
    background-color: $black;
    .loader-container {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
        .loader {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        p {
            margin-top: 40px;
            font-weight: bold;
            color: $white;
        }
    }
}

.loading-overlay {
    background-color: rgba(0, 0, 0, 0.75);
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 20;
}
