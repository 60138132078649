.discuss-controls {
    animation: slideInDiscussControls .5s ease-in-out forwards;
    right: 0;
    .timer {
        margin-right: 20px;
        margin-left: 20px;
    }
    .token-container {
        width: 90px;
        .token {
            margin: 0 auto;
            &__attachment {}
        }
    }
}