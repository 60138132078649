.start-scene {
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .title {
        font-size: 6rem;
        color: $white;
        text-transform: uppercase;
        white-space: nowrap;
        position: absolute;
        left: 40px;
        top: 40px;
        opacity: 0;
        animation: fadeIn .5s ease-in-out forwards !important;
    }
    .subtitle {
        font-size: 3rem;
        color: $white;
        font-weight: normal;
        position: absolute;
        left: 47px;
        top: 140px;
        opacity: 0;
        animation: fadeIn .5s ease-in-out forwards !important;
    }
    &__background {
        &__cards {
            &__right-top {
                position: fixed;
                top: 0;
                right: 0;
                right: -20px;
                z-index: 1;
                opacity: 0;
                animation: fadeIn .5s ease-in-out forwards !important;
                img {
                    &:first-of-type {
                        width: auto;
                        height: 40vh;
                        transform: rotate(-13deg);
                        box-shadow: 11px 10px 1px 0 rgba(0, 0, 0, 0.2);
                        z-index: 1;
                        position: relative;
                        border-radius: 15px;
                        top: -65px;
                        right: -30px;
                    }
                    &:last-of-type {
                        width: auto;
                        height: 37.5vh;
                        transform: rotate(15deg);
                        box-shadow: 11px 10px 1px 0 rgba(0, 0, 0, 0.2);
                        position: relative;
                        z-index: 0;
                        border-radius: 15px;
                    }
                }
            }
            &__left-bottom {
                position: fixed;
                left: 0;
                bottom: 0;
                bottom: -88px;
                z-index: 5;
                opacity: 0;
                animation: fadeIn .5s ease-in-out forwards !important;
                img {
                    &:first-of-type {
                        width: auto;
                        height: 50vh;
                        transform: rotate(-10deg);
                        box-shadow: 11px 10px 1px 0 rgba(0, 0, 0, 0.2);
                        position: relative;
                        z-index: 1;
                        border-radius: 15px;
                    }
                    &:last-of-type {
                        width: auto;
                        height: 40vh;
                        transform: rotate(20deg);
                        box-shadow: 11px 10px 1px 0 rgba(0, 0, 0, 0.2);
                        position: relative;
                        z-index: 0;
                        bottom: -20px;
                        left: -20px;
                        border-radius: 15px;
                    }
                }
            }
        }
        &__blue {
            background-color: $waterColor;
            height: calc(100% - 377px);
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
        }
    }
    .island {
        height: auto;
        position: absolute;
        right: 0;
        bottom: 20px;
        max-height: 70vh;
        z-index: 2;
        opacity: 0;
        animation: fadeIn .5s ease-in-out forwards !important;
        object-fit: contain;
    }
    &__level-controls {
        z-index: 4;
        position: absolute;
        margin: 0 auto;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 100px);
        animation: slideInLevelControls .5s ease-in-out forwards;
        background-color: $black;
        min-width: 300px;
        height: 80px;
        display: flex;
        justify-content: center;
        padding: 20px 50px;
        border-top-right-radius: 60px;
        border-top-left-radius: 60px;
        align-items: center;
        p {
            text-transform: uppercase;
            margin-right: 20px;
            font-size: 1rem;
            font-weight: bold;
            color: $white;
        }
        &__corner-left {
            position: absolute;
            background: $black;
            height: 40px;
            width: 40px;
            left: -40px;
            bottom: 0px;
            display: block;
            &::after {
                content: "";
                background-color: $waterColor;
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                right: 0;
                border-bottom-right-radius: 75%;
            }
        }
        &__corner-right {
            position: absolute;
            background: $black;
            height: 40px;
            width: 40px;
            right: -40px;
            bottom: 0px;
            display: block;
            &::after {
                content: "";
                background-color: $waterColor;
                height: 100%;
                width: 100%;
                position: absolute;
                left: 0;
                right: 0;
                border-bottom-left-radius: 75%;
            }
        }
        .token-container {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            animation: fadeIn .5s ease-in-out;
            .token__label {
                text-transform: uppercase;
                margin-right: 20px;
                font-size: 1rem;
                font-weight: bold;
            }
        }
    }
}