.player-with-wallet {
    display: flex;
    flex-direction: column-reverse;
    animation: fadeIn .5s ease-in-out;
    position: relative;
    transition: left .5s ease-in-out;
    &:before {
        content: '';
        width: 6px;
        height: 6px;
        border: 1px solid $white;
        position: absolute;
        left: -6px;
        background-color: $lightBackgroundColor;
        border-top-right-radius: 100%;
        z-index: 10;
        bottom: -3px;
        transform: rotateZ(225deg);
    }
    &__card-container {
        overflow: hidden;
        width: 100%;
        padding: 10px 10px 0 15px;
        border-bottom: 1px solid white;
        display: flex;
        justify-content: center;
        overflow: hidden;
        height: 185px;
        position: relative;
        .player-card {
            width: 100%;
            animation: slideUp .5s ease-in-out forwards;
            transform: translateY(40px);
            position: relative; 
            &:after {
                content: '';
                position: absolute;
                top: 192.5px;
                right: 0;
                left: 0;
                background: rgba(0, 0, 0, .1);
                width: 100%;
                height: 7.5px;
                z-index: 1;
            }
            img {
                box-shadow: 6px -6px 5px 0 rgba(0, 0, 0, 0.2);
                border-radius: 15px;
            }
        }
        // border-radius: 20px;
        // height: 200px;
        // background-color: $white;
        // margin: 0 20px;
        // width: 100%;
    }
    &__wallet {
        display: flex;
        width: calc(100% - 40px);
        margin: 3px auto 10px auto;
        justify-content: space-between;
        border-radius: 52px;
        border: 2px solid white;
        position: relative;
        &::after {
            content: '';
            height: 1px;
            background-color: $white;
            width: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -10px;
            overflow: visible;
        }
        &__pre {
            height: 1px;
            background-color: $white;
            width: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -10px;
            overflow: visible;
            &:before {
                content: '';
                width: 10px;
                height: 10px;
                border: 1px solid $white;
                position: absolute;
                left: -12.5px;
                border-top-right-radius: 100%;
                top: 50%;
                transform: translateY(-50%) rotateZ(225deg);
            }
        }
        .token {
            opacity: 1;
            transition: opacity .2s ease-in-out;
            &.inactive {
                background-color: transparent !important;
                filter: brightness(10);
            }
        }
    }
}