.feedback-dashboard {
    color: $white;
    font-weight: normal;
    padding: 66px 0 0 20px;
    height: 100%;
    width: calc(42.5% - 225px);
    &__general, &__other {
        opacity: 0;
        animation: fadeIn .3s ease-in-out forwards;
    }
    &__title-container {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        h3 {
            color: $grey;
            font-weight: normal;
            font-style: italic;
            white-space: nowrap;
            margin-right: 15px;
        }
        span {
            width: 100%;
            height: 1px;
            background-color: $grey;
            position: relative;
            &::after {
                content: '';
                top: -5px;
                right: -5px;
                position: absolute;
                @include lineChartRulerDot;
                z-index: 1;
            }
        }
    }
    .loader {
        margin: 20px auto;
    }
    &__inner {
        position: relative;
        height: 100%;
        animation: fadeIn .5s ease-in-out;
        margin-right: -20px;
        &__container {
            animation: fadeIn .5s ease-in-out forwards;
            max-height: 300px;
            margin-right: 20px;
        }
        h2 {
            margin-bottom: 20px;
        }
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 0;
            height: 0;
        }
    }
    .explanation {
        color: $white;
        // font-style: italic;
        // font-weight: bold;
    }
    p {
        margin-bottom: 20px;
        font-size: 1.15rem;
    }
}