.not-found-scene{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: $waterColor;
    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 40px;
        h1 {
            text-transform: uppercase;
            color: $white;
            font-size: 2rem;
        }
        p {
            font-size: 1rem;
            color: $white;
            position: relative;
            text-align: center;
            max-width: 500px;
            &::after {
                content: '';
                background-image: url("../../../assets/images/png/not-supported-background-addition.png");
                position: absolute;
                bottom: -150px;
                right: -150px;
                height: 150px;
                width: 150px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
        @media only screen and (max-width: 800px) {
            p {
                &::after {
                    right: 0;
                }
            }
        }
    }
}