.intro-scene {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    background-color: $black;
    .token-container {
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 1;
        padding: 5px;
        .token {
            margin-right: auto;
            margin-left: auto;
        }
    }
}