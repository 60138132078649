@import '../../fonts/stylesheet.css';

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $defaultFontSize;
    color: $defaultFontColor;

    font-family: $defaultFontFamily;
}

h1,h2,h3,h4,h5,h6 {
    font-family: $defaultHeadingFontFamily;
    margin: 0;
}

h1 {

}

h2 {

}

h3 {

}

h4 {

}

h5 {

}

h6 {
    
}

code {

}

p {
    margin: 0;
}

a {
    color: $bonusYellow;
}

span, p {
    &.highlight {
        font-weight: bold;
        font-style: normal;
    }
}
