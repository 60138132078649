.project-card {
    position: relative;
    &__info-toggle {
        font-size: .5rem;
        color: $grey;
        margin-left: 5px;
        margin-top: 1px;
        cursor: pointer;
        padding: 10px;
        margin: -20px -10px -20px -2px;
        z-index: 1;
    }
    &.completed {
        transform: scale(0.9);
        color: $white;
        background-color: #888888;
        .token {
            &.cant-make-move {
                opacity: 1;
            }
            background-color: $white;
            &:before {
                content: none;
            }
        }
        .title-info-appendix, .title-appendix {
            background-color: $white;
            &:after {
                border-color: $white !important;
                background-color: #888888;
            }
        }
        .project-card__inner__costs-container, .project-card__inner__yields-container {
            &__top {
                color: $white;
                font-style: italic;
                font-weight: normal;
                display: flex;
                .title-appendix {
                    background-color: $white;
                    &:after {
                        border-color: $white;
                    }
                }
            }
        }
        .project-card__completed-overlay {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(0, 0, 0, 0.65);
            z-index: 2;
            padding-top: 1px;
            margin-bottom: -1px;
            &__inner {
                color: $white;
                width: 100%;
                text-align: center;
                h4 {
                    font-weight: normal;
                    font-style: italic;
                }
                hr {
                    position: relative;
                    margin: 0px auto 5px auto;
                    width: calc(100% + 20px);
                    margin-left: -10px;
                    &::after {
                        content: '';
                        bottom: -5px;
                        right: -10px;
                        position: absolute;
                        @include titleAppendixEndHalfDot;
                        animation: fadeIn .5s ease-in-out;
                        background-color: transparent;
                        border-color: $white;
                    }
                    &::before {
                        content: '';
                        bottom: -5px;
                        left: -10px;
                        position: absolute;
                        @include titleAppendixEndHalfDot;
                        animation: fadeIn .5s ease-in-out;
                        background-color: transparent;
                        border-color: $white;
                        transform: rotateZ(225deg);
                    }
                }
            }
        }
    }
    &__completed-overlay {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;

    }
    .token {
        cursor: pointer !important;
        // margin: 5px;
        &__label {
            color: $grey;
            font-size: .6rem;
            width: 70px;
            font-weight: normal;
        }
    }
    &__cover {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: black;
        width: calc(100% + 15px);
        height: 100%;
        animation: openCard .5s ease-in-out forwards;
        z-index: 2;
    }
    background: $white;
    width: 225px;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
        height: 0;
    }
    &__inner {
        display: flex;
        flex-direction: column;
        padding: 10px;
        height: 100%;
        max-height: 355px;
        &__top {
            display: flex;
            align-items: center;
            p {
                font-size: .9rem;
                font-style: italic;
                margin: 0;
            }
        }
        &__title-container {
            h2 {
                font-size: 1.4rem;
                min-height: 90px;
                // margin-bottom: 20px;
            }
        }
        &__costs-container {
            padding-bottom: 10px;
            span {
                font-size: .75rem;
            }
            &__top {
                color: $grey;
                font-style: italic;
                font-weight: normal;
                display: flex;
            }
            &__costs {
                display: flex;
                flex-wrap: wrap;
                .project-cost {
                    position: relative;
                    transition: opacity .3s ease-in-out;
                    &__belongs-to {
                        width: 25px;
                        height: 25px;
                        position: absolute;
                        display: flex;
                        top: 6px;
                        z-index: 1;
                        transition: opacity .5s ease-in-out;
                        left: -3px;
                        filter: drop-shadow(0 2px rgba(0, 0, 0, 0.5));
                        align-items: center;
                        &__alt {
                            left: initial;
                            right: -3px;
                        }
                        div {
                            position: relative;
                            &:before {
                                content: '';
                                width: calc(100% + 2px);
                                height: calc(100% + 2px);
                                top: -1px;
                                left: -1px;
                                position: absolute;
                                z-index: 0;
                                background-color: $black;
                            }
                            &:after {
                                content: '';
                                width: 100%;
                                height: 100%;
                                top: 0;
                                left: 0;
                                position: absolute;
                                z-index: 1;
                            }
                            &.PF {
                                max-height: 22px;
                                &:before {
                                    clip-path: $pretShape;
                                }
                                &:after {
                                    background-color: $pretColor;
                                    clip-path: $pretShape;
                                }
                            }
                            &.GF {
                                &:before {
                                    clip-path: $grandShape;
                                }
                                &:after {
                                    background-color: $grandColor;
                                    clip-path: $grandShape;
                                }
                            }
                            &.IE {
                                max-height: 22px;
                                &:before {
                                    clip-path: $insolShape;
                                }
                                &:after {
                                    background-color: $insolColor;
                                    clip-path: $insolShape;
                                }
                            }
                            &.FL {
                                &:before {
                                    clip-path: $futShape;
                                }
                                &:after {
                                    background-color: $futColor;
                                    clip-path: $futShape;
                                }
                            }
                            &.FF {
                                &:before {
                                    clip-path: $foodShape;
                                }
                                &:after {
                                    background-color: $foodColor;
                                    clip-path: $foodShape;
                                }
                            }
                            &.II {
                                &:before {
                                    clip-path: $isleShape;
                                }
                                &:after {
                                    background-color: $isleColor;
                                    clip-path: $isleShape;
                                }
                            }
                            &.FG {
                                max-height: 22px;
                                &:before {
                                    clip-path: $feelShape;
                                }
                                &:after {
                                    background-color: $feelColor;
                                    clip-path: $feelShape;
                                }
                            }
                            &.CG {
                                &:before {
                                    clip-path: $govShape;
                                }
                                &:after {
                                    background-color: $govColor;
                                    clip-path: $govShape;
                                }
                            }
                            &.divider {
                                width: 2px;
                                background-color: $black;
                            }
                            width: 100%;
                            height: 100%;
                            
                        }
                    }
                    &__popup-toggle {
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        bottom: -2.5px;
                        right: 2.5px;
                        cursor: pointer;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                .token {
                    width: 42px;
                    height: 42px;
                    margin: 15px 4px 0px 4px;
                    &.inactive {
                        background-color: transparent;
                    }
                    &__label {
                        position: absolute;
                        margin-top: 1px;
                        span {
                            font-size: .5rem;
                        }
                    }
                }
            }
        }
        &__yields-container {
            opacity: 0.5;
            span {
                font-size: .75rem;
            }
            &__top {
                color: $grey;
                font-style: italic;
                font-weight: normal;
                display: flex;
            }
            &.active {
                opacity: 1;
            }
            &__yields {
                min-height: 72px;
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                padding-bottom: 10px;
                &__yield {
                    display: flex;
                    align-items: center;
                    margin: 10px 15px 0 0;
                    position: relative;
                    cursor: pointer;
                    &__popup-toggle {
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        bottom: -2.5px;
                        right: 2.5px;
                        cursor: pointer;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .caret-right {
                        border-right: 1px solid $grey;
                        border-top: 1px solid $grey;
                        width: 8px;
                        height: 8px;
                        margin-right: 10px;
                        margin-left: 5px;
                        transform: rotateZ(45deg);
                    }
                }
            }
        }
    }
}