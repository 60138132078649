.loader {
    &.light {
        background: $white;
        color: $white;
        &:before, &:after {
            background: $white;
        }
    }
    &.dark {
        background: $black;
        color: $black;
        &:before, &:after {
            background: $black;
        }
    }
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  
    &:before, &:after {
      -webkit-animation: load1 1s infinite ease-in-out;
      animation: load1 1s infinite ease-in-out;
      width: 1em;
      height: 4em;
    }
    text-indent: -9999em;
    position: relative;
    font-size: 6px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  
    &:before, &:after {
      position: absolute;
      top: 0;
      content: '';
    }
  
    &:before {
      left: -1.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }
  
    &:after {
      left: 1.5em;
    }
  }
  
  @-webkit-keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
  
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }
  
  @keyframes load1 {
    0%,
    80%,
    100% {
      box-shadow: 0 0;
      height: 4em;
    }
  
    40% {
      box-shadow: 0 -2em;
      height: 5em;
    }
  }