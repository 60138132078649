@import 'variables';
@import 'typography';
@import 'animations';
@import 'mixins';

// Import elements
@import './elements/loader';
@import './elements/project-card';
@import './elements/token';
@import './elements/timer';
@import './elements/popup';
@import './elements/playerWithWallet';
@import './elements/levelExplanation';
@import './elements/discussControls';
@import './elements/distributeControls';
@import './elements/distributeWallet';
@import './elements/playerCardStack';
@import './elements/playerCard';
@import './elements/progressDashboard';
@import './elements/lineChart';
@import './elements/playerRanking';
@import './elements/feedbackDashboard';
@import './elements/playersSlider';

// Import scenes
@import './scenes/intro';
@import './scenes/start';
@import './scenes/island';
@import './scenes/loading';
@import './scenes/discuss-and-distribute';
@import './scenes/notFound';
@import './scenes/player-introduction.scss';

img {
    object-fit: contain;
}

body, html, #root, .App {
    margin: 0;
    height: 100%;
    overflow: hidden;
}
.scene {
    margin: 0;
    height: 100%;
    overflow: visible;
}

.App {
    background-color: $lightBackgroundColor;
}

* {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.title-appendix {
    width: 100%;
    height: 1px;
    background-color: $grey;
    align-self: center;
    margin-left: 10px;
    position: relative;
    &::after {
        content: '';
        bottom: -3px;
        right: 0;
        position: absolute;
        @include titleAppendixEndDot;
        animation: fadeIn .5s ease-in-out;
    }
}

.title-info-appendix {
    width: 100%;
    height: 1px;
    background-color: $grey;
    align-self: center;
    margin-left: 10px;
    position: relative;
    &::after {
        content: '';
        bottom: -3px;
        right: -2px;
        position: absolute;
        @include titleAppendixEndHalfDot;
        animation: fadeIn .5s ease-in-out;
    }
}

.rcs-custom-scrollbar {
    opacity: 1 !important;
}

.rcs-inner-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 0;
        height: 0;
    }
}

.rcs-outer-container {
    position: relative;
    .rcs-positioning {
        position: initial !important;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 5px;
            bottom: 0;
            width: 1px;
            height: 100%;
            background-color: $grey;
        }
    }
}

.rcs-custom-scroll-handle {
    z-index: 2;
}
.rcs-inner-handle {
    border-radius: 4px !important;
    border: 1px solid $grey !important;
    background-color: $black !important;
    background: $black !important;
    width: 8px;
    cursor: pointer;
}

.popup {
    .project-card {
        .rcs-positioning {
            
        }
    }
}

.project-card {
    .rcs-outer-container {
        overflow: initial !important;
        .rcs-positioning {
            &::after {
                right: -8px;
                background-color: $white;
            }
            .rcs-custom-scrollbar {
                right: -10px !important;
                .rcs-inner-handle {
                    border: 1px solid $white !important;
                    background-color: $white !important;
                }
            }
        }
    }
}

.players-slider {
    .rcs-outer-container {
        overflow: initial !important;
        .rcs-positioning {
            &::after {
                right: 16px;
                background-color: $white;
            }
            .rcs-custom-scrollbar {
                right: 14px !important;
                .rcs-inner-handle {
                    border: 1px solid $white !important;
                    background-color: $white !important;
                }
            }
        }
    }
}

.fadeOut {
    animation: fadeOut .5s ease-in-out forwards !important;
}

.fadeIn {
    animation: fadeIn .5s ease-in-out forwards !important;
}

.screen-not-supported {
    display: none;
    opacity: 0;
    visibility: hidden;
    &.visible {
        display: flex;
        opacity: 1;
        visibility: visible;
        align-items: center;
        justify-content: center;
    }
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    background-color: $waterColor;
    &__inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 40px;
        h1 {
            text-transform: uppercase;
            color: $white;
            font-size: 2rem;
        }
        p {
            font-size: 1rem;
            color: $white;
            position: relative;
            &::after {
                content: '';
                background-image: url("../../assets/images/png/not-supported-background-addition.png");
                position: absolute;
                bottom: -150px;
                right: -150px;
                height: 150px;
                width: 150px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }
        @media only screen and (max-width: 800px) {
            p {
                &::after {
                    right: 0;
                }
            }
        }
    }
}

// Daan font size increases
@media screen and (min-width: 1366px) 
{

//main.scss
.player-introduction__content-container{max-width:1366px;}
.player-introduction__header__inner{max-width:1366px;}
.player-introduction__content-container .players-slider__inner .items .player-with-wallet .player-introduction-text h2{font-size:1.4rem;}
.player-introduction__content-container .players-slider__inner .items .player-with-wallet .player-introduction-text p{font-size: 1.15rem; margin-top: 0.5rem;}

//lineChart.scss
.line-chart__row__title-container h3{font-size: 1.25rem;}

//feedbackDashboard.scss
.feedback-dashboard p, .feedback-dashboard ul li{font-size: 1.25rem;}

//popup.scss
.popup p, .popup ul li{font-size: 1.4rem;}

//discuss-and-distribute.scss
.discuss-and-distribute-scene{max-width: 1366px;}

//project-card.scss
.project-card__inner__title-container h2{font-size: 1.5rem;}
    
}
