.popup {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10;
    animation: fadeIn .2s ease-in-out forwards;
    p, ul li {
        font-size: 1.15rem;
    }
    h2 {
        font-size: 1.4rem;
    }
    &__end-result {
        background: initial;
        pointer-events: none;
        .popup__container {
            position: absolute;
            top: initial;
            left: initial;
            transform: initial !important;
            bottom: 20px;
            right: 20px;
            max-width: 450px !important;
            z-index: 99;
            &__inner {
                &__content {
                    display: flex;
                }
            }
            max-width: 600px;
        }
        .description-container {
            padding-right: 40px;
        }
    }
    &__discussion-explanation, &__distribution-explanation {
        .popup__container__inner {
            &__content {
                display: flex;
                align-items: center;
                .content {
                    max-width: 275px;
                    margin: 40px;
                }
            }
        }
        .title-container {
            margin: 0 20px;
            position: relative;
            &:before {
                content: '';
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                height: 1px;
                background-color: $white;
                width: 10px;
                left: -12.5px;
            }
            &:after {
                content: '';
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                height: 1px;
                background-color: $white;
                width: 10px;
                right: -12.5px;
            }
            h2 {
                margin: 0;
                color: $white !important;
                font-size: 2.625rem;
                white-space: nowrap;
                position: relative;
                &:before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border: 1px solid $white;
                    position: absolute;
                    left: -25px;
                    border-top-right-radius: 100%;
                    top: 50%;
                    transform: translateY(-50%) rotateZ(225deg);
                }
                &:after {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border: 1px solid $white;
                    position: absolute;
                    right: -25px;
                    border-top-right-radius: 100%;
                    top: 50%;
                    transform: translateY(-50%) rotateZ(45deg);
                }
            }
        }
    }
    &__yield-info, &__cost-info {
        .popup__container__inner__content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .player-card__inner {
                img {
                    height: 267px;
                    width: 168px;
                }
            }
            .card-illustration-container {
                &.alt {
                    .card-illustration-container__symbol-left {
                        top: initial;
                        transform: none;
                        bottom: 25%;
                        left: 25px;
                    }
                    .card-illustration-container__symbol-right {
                        top: initial;
                        transform: none;
                        bottom: 25%;
                        right: 20px;
                    }
                    .token-icon {
                        right: 30px;
                        top: 20px;
                    }
                    .card-illustration-container__inner {
                        padding-right: 60px;
                        padding-bottom: 80px;
                    }
                    position: relative;
                    .card-container {
                        position: relative;
                        .token-container {
                            left: -10px;
                            bottom: 40px;
                            right: initial;
                            top: initial;
                        }
                        &:last-of-type {
                            position: absolute;
                            top: 80px;
                            right: 0;
                            .token-container {
                                bottom: 5px;
                                left: -12.5px;
                            }
                            .player-card {
                                transform: rotate(10deg);
                            }
                        }
                    }
                }
                padding: 30px;
                position: relative;
                &__inner {
                    position: relative;
                }
                &__symbol-left {
                    height: 1px;
                    position: absolute;
                    width: 10px;
                    background-color: $white;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 5px;
                    &:before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        border: 1px solid $white;
                        position: absolute;
                        left: -8px;
                        border-top-right-radius: 100%;
                        background-color: $lightBackgroundColor;
                        top: 50%;
                        transform: translateY(-50%) rotateZ(225deg);
                    }
                }
                &__symbol-right {
                    position: absolute;
                    height: 1px;
                    width: 10px;
                    background-color: $white;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 5px;
                    &:before {
                        content: '';
                        width: 8px;
                        height: 8px;
                        border: 1px solid $white;
                        position: absolute;
                        right: -8px;
                        border-top-right-radius: 100%;
                        background-color: $lightBackgroundColor;
                        top: 50%;
                        transform: translateY(-50%) rotateZ(45deg);
                    }
                }
                .token-container {
                    position: absolute;
                    top: -20px;
                    right: 25px;
                    z-index: 2;
                }
                .token-icon {
                    z-index: 1;
                    position: absolute;
                    top: 5px;
                    right: -20px;
                    width: 80px;
                    height: 80px;
                }
                .player-card {
                    transform: rotate(-8deg);
                    z-index: 1;
                }
            }
            .description-container {
                padding: 0 30px;
                max-width: 270px;
                h2 {
                    font-size: 16px;
                    text-align: left;
                }
                p {

                }
            }
        }
    }
    &__skip-discuss, &__skip-distribute, &__pause-timer {
        .popup__container__inner__content {
            p {
                &:last-of-type {
                    margin-bottom: 20px;
                }
            }
        }
    }
    &__skip-discuss, &__skip-distribute, &__confirm-move, &__invalid-move, &__discussion-explanation, &__distribution-explanation, &__yield-info, &__cost-info, &__pause-timer {
        h2 {
            color: $black;
            margin-bottom: 20px;
            text-align: center;
        }
        p {
            margin-bottom: 20px;
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &__project-info {
        .project-card {
            &__inner {
                &__title-container {
                    h2 {
                        font-size: 1.4rem;
                    }
                }
            }
        }
        h2 {
            font-size: .875rem;
            font-weight: normal;
            margin: 20px 0;
        }
        .token-container {
            .token__label {
                position: absolute;
                left: 0;
                right: 0;
            }
        }
        .popup__container__inner {
            &__content {
                display: flex;
                align-items: center;
            }
            display: flex;
            padding: 30px;
            align-items: center;
            .description-container {
                margin: 0 15px;
                padding: 0 15px;
                max-width: 400px;
                max-height: 400px;
                overflow: auto;
                align-self: flex-start;
            }
        }
        .project-card {
            &__inner {
                &__top, &__title-container, &__yields-container, &__costs-container {
                    pointer-events: none;
                }
                &__title-container {
                    h2 {
                        margin: 0;
                    }
                }
            }
            &__cover {
                display: none;
            }
        }
    }
    &__container {
        animation: popUpAndTranslateY .2s ease-in-out forwards;
        width: max-content;
        background: $white;
        border-radius: 20px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        left: 0;
        right: 0;
        padding: 20px;
        max-height: calc(100% - 80px);
        &__outer {
            padding: 10px;
            background-color: $lightBackgroundColor;
            position: relative;
            &__circle-line {
                position: absolute;
                width: 25px;
                height: 25px;
                background-color: $lightBackgroundColor;    
                border-radius: 100%;
                overflow: visible;
                z-index: 1;
                img {
                    width: 25px;
                    height: 25px;
                }
                &.top-left {
                    top: 0;
                    left: 0;
                    img {
                        position: relative;
                        top: 5px;
                        left: 5px;
                        transform: rotateZ(90deg);
                    }
                }
                &.top-right {
                    top: 0;
                    right: 0;
                    img {
                        position: relative;
                        top: 5px;
                        right: 5px;
                        transform: rotateZ(180deg);
                    }
                }
                &.bottom-left {
                    bottom: 0;
                    left: 0;
                    img {
                        position: relative;
                        bottom: 5px;
                        left: 5px;
                        transform: rotateZ(360deg);
                    }
                }
                &.bottom-right {
                    bottom: 0;
                    right: 0;
                    img {
                        position: relative;
                        bottom: 5px;
                        right: 5px;
                        transform: rotateZ(270deg);
                    }
                }
            }
        }
        &__corner-circle {
            position: absolute;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background: $white;
            z-index: 2;
            &::after {
                content: "";
                position: absolute;
                height: 6px;
                width: 6px;
                border-radius: 100%;
                border: 1px solid $black;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &.top-left {
                top: 0;
                left: 0;
            }
            &.top-right {
                top: 0;
                right: 0;
            }
            &.bottom-left {
                bottom: 0;
                left: 0;
            }
            &.bottom-right {
                bottom: 0;
                right: 0;
            }
        }
        &__inner {
            padding: 30px;
            background-color: $lightBackgroundColor;
            border: 1px solid $black;
            position: relative;
            &__border-symbol {
                position: absolute;
                width: 8px;
                height: 8px;
                border: 1px solid $black;
                background: $lightBackgroundColor;
                &.left {
                    left: -7px;
                    top: calc(50% - 1.25px);
                    transform: rotatez(45deg) translateY(-50%);
                }
                &.top {
                    top: -2.5px;
                    transform: rotatez(45deg) translateX(-50%);
                    right: calc(50% - 7.5px);
                }
                &.bottom {
                    bottom: -7.5px;
                    transform: rotatez(45deg) translateX(-50%);
                    right: calc(50% - 7.5px);
                }
                &.right {
                    right: -1.5px;
                    top: calc(50% - 1.25px);
                    transform: rotatez(45deg) translateY(-50%);
                }
            }
            .button-controls {
                display: flex;
                justify-content: center;
                align-items: center;
                .token-container {
                    .token {
                        margin: 0 auto;
                    }
                    margin: 0 10px;
                }
            }
        }
    }
}