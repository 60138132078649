// Colors
$black: #000000;
$dark: rgb(49, 49, 49);
$white: #ffffff;
$grey: rgb(103, 148, 160);
$darkBackgroundColor: $black;
$lightBackgroundColor: rgb(204, 229, 243);
$waterColor: rgb(105, 152, 167);
$popupBackgroundColor: rgb(147, 184, 200);
$bonusYellow: rgb(215, 158, 57);

// Player colors
$pretColor: rgb(224, 94, 43);
$grandColor: rgb(238, 166, 77);
$insolColor: rgb(78, 157, 174);
$futColor: rgb(81, 165, 140);
$foodColor: rgb(175, 108, 69);
$isleColor: rgb(173, 188, 198);
$feelColor: rgb(243, 164, 138);
$govColor: rgb(202, 17, 59);

// Player shapes
$pretShape: polygon(50% 0, 0 100%, 100% 100%);
$grandShape: polygon(50% 0, 100% 50%, 50% 100%, 0% 50%);
$insolShape: polygon(50% 0, 0 100%, 100% 100%);
$futShape: circle(50% at 50% 50%);
$foodShape: circle(50% at 50% 50%);
$isleShape: polygon(50% 0, 100% 50%, 50% 100%, 0% 50%);
$feelShape: polygon(50% 0, 0 100%, 100% 100%);
$govShape: circle(50% at 50% 50%);


// Font variables
$defaultFontSize: 16px;
$defaultFontColor: $black;
$defaultFontFamily: 'etelka_text_proregular', sans-serif, 'Arial';
$defaultHeadingFontFamily: 'etelka_text_probold', sans-serif, 'Arial';
