.line-chart {
    width: 57.5%;
    display: flex;
    padding: 40px 40px 40px 40px;
    position: relative;
    height: 100%;
    &__rows {
        height: calc(100% - 38px); // minus top bar with level labels
    }
    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        position: relative;
    }
    &__rulers {
        position: absolute;
        bottom: 0;
        top: 0;
        z-index: 0;
        right: 0;
        width: calc(100% - 60px);
        margin: 80px 0 0 0;
        animation: fadeIn .5s ease-in-out;
        &__ruler {
            position: absolute;
            height: 100%;
            width: 1px;
            background-image: linear-gradient($grey 10%, rgba(255,255,255,0) 0%);
            background-position: right;
            background-size: 1px 8px;
            background-repeat: repeat-y;
            &:first-of-type {
                &::before {
                    background-color: $black;
                    border: none;
                    height: 40px;
                }
            }
            // &::before {
            //     content: '';
            //     top: -5px;
            //     right: -5px;
            //     position: absolute;
            //     @include lineChartRulerDot;
            //     animation: fadeIn .5s ease-in-out;
            // }
        }
    }
    &__top {
        width: 100%;
        display: flex;
        flex-direction: row;
        z-index: 1;
        &__col {
            width: 25%;
            &:first-child {
                width: calc(25% + 60px);
                padding-left: 60px;
            }
            h2 {
                &.active {
                    opacity: 1;
                    white-space: nowrap;
                }
                color: $white;
                font-size: 1.875rem;
                opacity: 0.25;
                transition: opacity .5s ease-in-out;
                text-align: center;
            }
        }
        &__pre {
            width: 60px;
            min-width: 60px;
        }
    }
    &__row {
        z-index: 1;
        padding-bottom: 20px;
        height: 33.333333%;
        border-top: 1px solid $grey;
        position: relative;
        &:last-of-type {
            border-bottom: 1px solid $grey;
            &::before {
                content: '';
                bottom: -1px;
                right: -20px;
                position: absolute;
                width: 20px;
                height: 1px;
                background-color: $grey;
            }
        }
        &::after {
            content: '';
            top: -1px;
            right: -20px;
            position: absolute;
            width: 20px;
            height: 1px;
            background-color: $grey;
        }
        &:first-of-type {
            .line-chart__row__title-container {
                &::before {
                    content: '';
                    top: -5px;
                    right: -20px;
                    position: absolute;
                    @include lineChartRulerDot;
                    z-index: 1;
                }
            }
        }
        &__title-container {
            display: flex;
            align-items: center;
            position: relative;
            h3 {
                white-space: nowrap;
                background: $black;
                display: flex;
                align-items: center;
                color: $grey;
                height: 40px;
                font-weight: normal;
                font-size: 1rem;
                font-style: italic;
                padding-left: 5px;
                padding-right: 20px;
                margin-left: -5px;
                span {
                    margin-left: 10px;
                    display: flex;
                    align-items: center;
                    line-height: 1;
                    margin-top: 2px;
                    span {
                        display: flex;
                        align-items: center;
                        margin-left: 5px;
                        line-height: 1;
                        margin-top: 0;
                    }
                }
            }
            padding-left: 60px;
        }
        &__graph-container {
            display: flex;
            flex-direction: row;
            width: 100%;
            height: calc(100% - 40px); // minus title bar on top of column
            position: relative;
            // margin-bottom: 25px;
        }
        &__pre {
            width: 60px;
            min-width: 60px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            &::before {
                content: '';
                top: -4px;
                right: -4px;
                position: absolute;
                @include lineChartRulerDot;
                animation: fadeIn .5s ease-in-out;
            }
            &::after {
                content: '';
                bottom: -4px;
                right: -4px;
                position: absolute;
                @include lineChartRulerDot;
                animation: fadeIn .5s ease-in-out;
            }
            &__max-value {
                color: $grey;
                height: 40px;
                text-align: right;
                padding-right: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                top: -18px;
                span {
                    line-height: 1;
                    margin-top: 3px;
                }
            }
            &__between-value {
                color: $grey;
                height: 40px;
                text-align: right;
                padding-right: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: absolute;
                display: none;
                span {
                    line-height: 1;
                    margin-top: 3px;
                }
            }
            &__min-value {
                color: $grey;
                height: 40px;
                text-align: right;
                padding-right: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;
                top: 16px;
                span {
                    line-height: 1;
                    margin-top: 3px;
                }
            }
        }
        &__col {
            width: 25%;
            position: relative;
            &::before {
                content: '';
                top: -4px;
                right: -4px;
                position: absolute;
                @include lineChartRulerDot;
                animation: fadeIn .5s ease-in-out;
            }
            &::after {
                content: '';
                bottom: -4px;
                right: -4px;
                position: absolute;
                @include lineChartRulerDot;
                animation: fadeIn .5s ease-in-out;
            }
            &.prognose {
                .line-chart__row__col__line {
                    background-color: rgba(255, 255, 255, 0.35);
                    &::after {
                        background-color: rgba(255, 255, 255, 0.35);
                    }
                    &__label {
                        color: rgba(204, 229, 243, .5);
                    }
                }
            }
            &__line {
                width: 100%;
                height: 1px;
                background-color: $white;
                width: 100%;
                position: absolute;
                transform-origin: left;
                width: 0;
                transition: width .350s ease-in-out;
                z-index: 1;
                z-index: 999;
                &__label {
                    position: absolute;
                    right: -20px;
                    top: 10px;
                    width: 40px;
                    height: 20px;
                    line-height: 20px;
                    z-index: 999;
                    color: $lightBackgroundColor;
                    text-align:center;
                    background-color: $black;
                    opacity: 0;
                    animation: fadeIn 350ms ease-in-out forwards;
                }
                &.first {
                    &::before {
                        content: '';
                        width: 11px;
                        height: 11px;
                        border-radius: 7.5px;
                        background-color: $white;
                        position: absolute;
                        left: -5.5px;
                        top: -5.5px;
                        opacity: 0;
                        animation: fadeIn .350s ease-in-out forwards;
                    }
                }
                &::after {
                    content: '';
                    width: 11px;
                    height: 11px;
                    border-radius: 7.5px;
                    background-color: $white;
                    position: absolute;
                    right: -5.5px;
                    top: -5.5px;
                    opacity: 0;
                    animation: fadeIn .350s ease-in-out forwards;
                }
            }
        }
    }

    .additional_data_point {
        position: absolute;
        @include lineChartRulerDot;
        right: -4px;
        height: 8px;
        width: 8px;
        bottom: calc(66.66667% - 4px);
        animation: fadeIn .5s ease-in-out;
        span {
            color: $grey;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
            left: -42px;
            font-size: .75rem;
        }
    }
}