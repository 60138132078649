.island-scene {

    .progress-dashboard {
        height: 500px;
    }

    &__bottom {
        background-color: $waterColor;
        display: flex;
        position: relative;
        height: calc(100% - 500px);
        transition: height 1s ease-in-out;
        &__left {
            width: calc(100% - 745px);
            padding: 40px 80px 40px 40px;
            display: flex;
            align-items: flex-end;
            &__content {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: calc(50% - 220px);
                p {
                    white-space: nowrap;
                    padding-right: 30px;
                    color: $white;
                }
                .line {
                    height: 1px;
                    width: 100%;
                    background-color: $white;
                }
            }
        }
        &__island {
            width: 100%;
            display: flex;
            color: $white;
            align-items: center;
            justify-content: center;
            position: relative;
            animation: fadeIn .5s ease-in-out;
            overflow: visible;
            .island {
                position: fixed;
                right: -20px;
                top: 350px;
                width: 750px;
                z-index: 1;
                animation: fadeIn .5s ease-in-out;
            }
            @media only screen and (min-height: 800px) {
                .island {
                    top: 330px;
                    width: 900px;
                }
            }
            @media only screen and (min-height: 900px) {
                .island {
                    top: 320px;
                    width: 1000px;
                }
            }
            @media only screen and (min-height: 950px) {
                .island {
                    top: 300px;
                    width: 1200px;
                }
            }
        }
        &__level-controls {
            position: absolute;
            margin: 0 auto;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 100px);
            animation: slideInLevelControls .5s ease-in-out forwards;
            background-color: $black;
            min-width: 300px;
            min-height: 80px;
            display: flex;
            justify-content: center;
            padding: 10px 50px;
            border-top-right-radius: 60px;
            border-top-left-radius: 60px;
            align-items: center;
            z-index: 10;
            p {
                text-transform: uppercase;
                margin-right: 20px;
                font-size: 1rem;
                font-weight: bold;
                color: $white;
            }
            &__corner-left {
                position: absolute;
                background: $black;
                height: 40px;
                width: 40px;
                left: -40px;
                bottom: 0px;
                display: block;
                &::after {
                    content: "";
                    background-color: $waterColor;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    border-bottom-right-radius: 75%;
                }
            }
            &__corner-right {
                position: absolute;
                background: $black;
                height: 40px;
                width: 40px;
                right: -40px;
                bottom: 0px;
                display: block;
                &::after {
                    content: "";
                    background-color: $waterColor;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    border-bottom-left-radius: 75%;
                }
            }
            .token-container {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                animation: fadeIn .5s ease-in-out;
                .token__label {
                    text-transform: uppercase;
                    margin-right: 20px;
                    font-size: 1rem;
                    font-weight: bold;
                }
            }
        }
    }


    // Transition out animation
    &.transition {
        &.showing-game-results {
            .top-left-level-info {
                position: fixed;
                z-index: 99;
            }
            .island-scene__bottom__level-controls {
                position: fixed;
                // z-index: 99;
                .island-scene__bottom__level-controls__corner-left {
                    &::after {
                        // background-color: rgb(26, 38, 42) !important;
                    }
                }
                .island-scene__bottom__level-controls__corner-right {
                    &::after {
                        // background-color: rgb(26, 38, 42) !important;
                    }
                }
            }
        }
        .island-scene__bottom {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: calc(100% - 20vw);
        }
        #line-chart, #feedback-dashboard, #player-ranking, .island-scene__bottom__left__content {
            animation: fadeOut .5s ease-in-out forwards !important;
        }
        .island {
            animation: islandFullScreen 1s ease-in-out forwards !important;
        }
        .island-scene__bottom__level-controls {
            &:not(.slideBackIn) {
                animation: slideOutLevelControls .5s ease-in-out forwards !important;
            }
        }
        .top-left-level-info {
            opacity: 0;
            animation: fadeIn .5s ease-in-out forwards 1.5s !important;
        }
        .island-scene__background {
            &__cards {
                &__right-top, &__left-bottom {
                    animation: fadeIn .5s ease-in-out forwards !important;
                }
            }
        }
        &.show-game-results {
            .fade-out-background {
                animation: none !important;
            }
        }
        .fade-out-background {
            animation: fadeIn 1s ease-in-out forwards 4s !important;
        }
    }

    .top-left-level-info {
        position: absolute;
        top: 40px;
        left: 40px;
        opacity: 0;
        pointer-events: none;
        z-index: 1;
        h2, h3 {
            color: $white;
            text-transform: uppercase;
            font-size: 5.5rem;
            margin: 0;
            padding: 0;
        }
        h3 {
            font-size: 1.5em !important;
            font-weight: normal !important;
            position: relative;
            left: 5px;
        }
    }

    .fade-out-background {
        position: fixed;
        z-index: 20;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        opacity: 0;
        pointer-events: none;
    }

    &__background {
        &__cards {
            &__right-top {
                opacity: 0;
                position: fixed;
                top: 0;
                right: 0;
                right: -20px;
                z-index: 1;
                pointer-events: none;
                img {
                    &:first-of-type {
                        width: 13.5vw;
                        height: auto;
                        transform: rotate(-13deg);
                        box-shadow: 11px 10px 1px 0 rgba(0, 0, 0, 0.2);
                        z-index: 1;
                        position: relative;
                        border-radius: 15px;
                        top: -10vw;
                        right: -30px;
                    }
                    &:last-of-type {
                        width: 12vw;
                        top: -5vw;
                        height: auto;
                        transform: rotate(15deg);
                        box-shadow: 11px 10px 1px 0 rgba(0, 0, 0, 0.2);
                        position: relative;
                        z-index: 0;
                        border-radius: 15px;
                    }
                }
            }
            &__left-bottom {
                opacity: 0;
                position: fixed;
                left: 0;
                bottom: 0;
                bottom: -120px;
                z-index: 5;
                pointer-events: none;
                img {
                    &:first-of-type {
                        width: 200px;
                        height: auto;
                        transform: rotate(-10deg);
                        box-shadow: 11px 10px 1px 0 rgba(0, 0, 0, 0.2);
                        position: relative;
                        z-index: 1;
                        border-radius: 15px;
                    }
                    &:last-of-type {
                        width: 180px;
                        height: auto;
                        transform: rotate(20deg);
                        box-shadow: 11px 10px 1px 0 rgba(0, 0, 0, 0.2);
                        position: relative;
                        z-index: 0;
                        bottom: -40px;
                        left: -40px;
                        border-radius: 15px;
                    }
                }
            }
        }
    }
}

.show-result-controls {
    bottom: initial !important;
    top: 0;
    padding-left: 40px !important;
    padding-right: 40px !important;
    animation: slideInShowResultControls .5s ease-in-out forwards;
    .controls-container__right-top, .controls-container__left-top {
        top: 0;
        &::after {
            background-color: $waterColor;
        }
    }
    .token {
        &__attachment {
            left: 50%;
            transform: translateX(-50%);
            &::before {
                background-color: $waterColor;
            }
            span {
                color: $white;
                white-space: nowrap;
            }
        }
    }
}

.island {
    position: fixed;
    background-color: transparent;
    z-index: 1;
    width: 960px;
    &__background {
        width: 100%;
        height: auto;
    }
    .island-sprite {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        width: 100%;
        height: auto;
        &.new {
            opacity: 0;
            &.fadeIn {
                animation: fadeInDisplayBlock .3s ease-in-out forwards;
            }
        }
    }
}
