.distribute-controls-wallet {
    animation: slideInDistributeControlsWallet .5s ease-in-out forwards;
    left: 0;
    bottom: -64px !important;
    .timer {
        margin-right: 10px;
        margin-left: 10px;
    }
    .tokens-container {
        display: flex;
        position: relative;
        border: 1px solid rgba(255, 255, 255, 0.4);
        border-radius: 50px;
        padding: 5px 10px;
        margin-top: -10px;
        &:after {
            content: '';
            transform: rotateZ(45deg);
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: $lightBackgroundColor;
            display: block;
            bottom: -27.5px;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
    .token-container {
        margin-right: 20px;
        &:last-of-type {
            margin-right: 0;
        }
        .token {
            &.inactive {
                filter: brightness(10);
                background-color: transparent;
            }
        }
    }
}