.can-make-move {
    &.hover {
        animation: scaleUp .2s ease-in-out forwards;
    }
    opacity: 1;
}
.cant-make-move {
    opacity: 0.25;
}

.token-container {
    width: max-content;
    position: relative;
    user-select: none;
    span.effect {
        position: absolute;
        left: 20px;
        top: -3px;
        color: $white;
        animation: tokenEffect 500ms ease-in-out forwards;
        z-index: 1;
        font-size: 1rem;
        font-weight: bold;
        text-shadow: 2px 2px 4px $black;
    }
    &.apply-consequence {
        animation: applyTokenConsequence 400ms ease-in-out forwards;
        .token {
            animation-delay: 400ms !important;
            &.CG {
                animation: pulseCG 400ms ease-in-out;
            }
            &.GF {
                animation: pulseGF 400ms ease-in-out;
            }
            &.PF {
                animation: pulsePF 400ms ease-in-out;
            }
            &.IE {
                animation: pulseIE 400ms ease-in-out;
            }
            &.FL {
                animation: pulseFL 400ms ease-in-out;
            }
            &.II {
                animation: pulseII 400ms ease-in-out;
            }
            &.FF {
                animation: pulseFF 400ms ease-in-out;
            }
            &.FG {
                animation: pulseFG 400ms ease-in-out;
            }
        }
    }
}
.token {
    width: 50px;
    height: 52px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    cursor: pointer;
    box-shadow: 0 3px rgba(0, 0, 0, .1);
    transition: opacity .5s ease-in-out;

    
    &.pulse {
        transform: scale(1);
        animation: pulse 2s infinite;
    }
    
    img {
        width: 100%;
        height: auto;
    }
    &.dragging {
        position: absolute !important;
        top: 0;
        left: 0;
        z-index: 10;
        pointer-events: none;
    }
    * {
        pointer-events: none;
    }
    &.small {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        .token__amount {
            top: -3px;
            left: -30px;
        }
    }
    &.inactive {
        &.BONUS {
            img {
                opacity: 1 !important;
            }
        }
        background-color: transparent;
        cursor: initial;
        box-shadow: none;
        padding: 0 !important;
        img {
            width: 100%;
            height: 100%;
            opacity: .65;
        }
        &:before {
            content: none !important;
        }
    }
     
    &__attachment {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        position: absolute;
        top: 64px;
        &:before {
            content: '';
            transform: rotateZ(45deg);
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: $lightBackgroundColor;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 0;
        }
        span {
            color: $grey;
            z-index: 1;
            font-weight: bold;
            font-style: italic;
            text-align: center;
        }
    }
    &__amount {
        position: absolute;
        top: -5px;
        left: -5px;
        background-color: $white;
        color: $grey;
        height: 25px;
        width: 25px;
        border-radius: 12.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 3px rgba(0, 0, 0, .1);
        span {
            font-size: 1.25rem;
        }
    }
    &__label {
        font-weight: bold;
        color: $white;
        text-align: center;
        margin-top: 10px;
        position: relative;
        span {
            
        }
    }


    &.PF {
        background-color: $pretColor;
    }
    &.GF {
        background-color: $grandColor;
    }
    &.IE {
        background-color: $insolColor;
    }
    &.FL {
        background-color: $futColor;
    }
    &.FF {
        background-color: $foodColor;
    }
    &.II {
        background-color: $isleColor;
    }
    &.FG {
        background-color: $feelColor;
    }
    &.CG {
        background-color: $govColor;
    }
    &.PF, &.GF, &.IE, &.FL, &.FF, &.II, &.FG, &.CG, &.K, &.G, &.A {
        position: relative;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            border-bottom: 3px solid rgba(0, 0, 0, 0.3);
            border-radius: 100%;
        }
        padding: 4px;
    }
}