.player-card-stack {
    display: flex;
    position: relative;
    height: 320px;
    bottom: -105px;
    animation: fadeIn .5s ease-in-out;
    left: 0;
    .player-card {
        left: 0;
        top: 0;
        width: 225px;
        height: auto;   
        margin: 0 10px;
        position: absolute;
        transition: transform .2s ease-in-out;
        &.active {
            left: 0 !important;
        }
    }
}

@media only screen and (min-height: 760px) {
    .player-card-stack {
        left: -10px;
        .player-card {
            width: 250px !important;
        }
    }
}

@media only screen and (min-height: 820px) {
    .player-card-stack {
        left: -25px;
        .player-card {
            width: 275px !important;
        }
    }
}

@media only screen and (min-height: 900px) {
    .player-card-stack {
        left: -35px;
        .player-card {
            width: 300px !important;
        }
    }
}
