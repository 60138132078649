.timer {
    &.clone {
        position: fixed;
        top: 40px;
        right: 40px;
        border-color: $white;
        .timer__inner-circle {
            border-color: $white;
        }
        .timer__indicator__pointer {
            background-color: $white;
            &::before {
                border-color: $white;
            }
        }
        .timer__attachment {
            margin-top: 0;
            padding-top: 0;
            &::before {
                content: none;
                display: none;
            }
            span {
                color: $white;
            }
        }
    }
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
    border: 1px solid $grey;
    position: relative;
    padding: 5px;
    animation: fadeIn .5s ease-in-out;
    &__inner-circle {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        border: 1px solid $grey;
    }
    &__indicator {
        width: 100%;
        height: 100%;
        position: absolute;
        &__pointer {
            position: absolute;
            width: 1px;
            background-color: $grey;
            height: 50%;
            left: 50%;
            transform: translateX(-50%) rotateZ(0);
            transform-origin: bottom;
            &::before {
                content: '';
                top: -4px;
                right: -2px;
                position: absolute;
                width: 4px;
                height: 4px;
                display: block;
                border-radius: 2px;
                border: 1px solid $grey;
                background: black;
            }
            &:first-of-type {

                &::before {
                    transform: rotateZ(45deg);
                    border-radius: 0 !important;
                }
            }
        }
    }
    &__attachment {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        position: absolute;
        top: 64px;
        z-index: 0;
        font-size: 1rem;
        &:before {
            content: '';
            transform: rotateZ(45deg);
            position: absolute;
            width: 15px;
            height: 15px;
            background-color: $lightBackgroundColor;
            display: block;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
        }
        &__countdown, &__phase {
            color: $grey;
            z-index: 1;
            font-weight: bold;
            font-style: italic;
            text-align: center;
        }
    }
}